export default () => ({
	submissionData: {
		selectedStage: null,
		toolkitOpen: false,
		worldViewsVisible: true,
		showCapabilities: true,
		showHigh: true,
		showMid: true,
		showLatent: true,
		worldViews: [],
		highlightStages: [],
		selectedValues: []
	},
	clearActiveValues() {
		this.submissionData.selectedValues = [];
	},
	toggleActiveValue(slug, forceState = null) {
		if (forceState === null) {
			// add/remove slug from selectedValues array
			if (this.submissionData.selectedValues.includes(slug)) {
				console.log("removing", slug);
				this.submissionData.selectedValues = this.submissionData.selectedValues.filter(
					value => value !== slug
				);
			} else {
				console.log("adding", slug);
				this.submissionData.selectedValues.push(slug);
			}
		} else {
			// force the state of the value to true/false
			if (forceState) {
				console.log(`forcing ${slug} to true`);
				this.submissionData.selectedValues.push(slug);
			} else {
				console.log(`forcing ${slug} to false`);
				this.submissionData.selectedValues = this.submissionData.selectedValues.filter(
					value => value !== slug
				);
			}
		}
		console.log('it is now:"', this.submissionData.selectedValues);
	},
	toggleworldView(slug) {
		// set slug as the only value in selectedValues array
		if (this.submissionData.worldViews.includes(slug)) {
			console.log("removing", slug);
			this.submissionData.worldViews = [];
		} else {
			console.log("adding", slug);
			this.submissionData.worldViews = [slug];
		}
	},
	toggleStageColumns(stages = []) {
		this.submissionData.highlightStages = stages;
		// stages.forEach(stage => {
		// 	// Note: the below does not provide very good UX... better to just only show the defined aerray at a time.
		// 	// if (this.submissionData.highlightStages.includes(stage)) {
		// 	// 	console.log(`removing ${stage} from highlighted columns`);
		// 	// 	this.submissionData.highlightStages = this.submissionData.highlightStages.filter(value => value !== stage)
		// 	// } else {
		// 	// 	console.log(`adding ${stage} from highlighted columns`);
		// 	// 	this.submissionData.highlightStages.push(stage)
		// 	// }
		// })
	},
	init() {
		// do stuff on initialisation
	}
});
